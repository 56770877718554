<template>
  <div v-if="featureStore.featureOrderManagment">
    <HeaderNavbar :title="pageTitle" :breadcrumbs="listBreadcrumbs">
      <template #left-side>
        <div class="filters-list">
          <ToggleButton
            class="status"
            :active="!filters.filter_order_lists"
            :text="$t('orders.list.filter_all')"
            @click="setOrderTypeFilter('')"
          />
          <ToggleButton
            class="status"
            :active="filters.filter_order_lists === 'all_active'"
            :text="$t('orders.list.filter_active')"
            @click="setOrderTypeFilter('all_active')"
          />
          <ToggleButton
            class="status"
            :active="filters.filter_order_lists === 'in_past'"
            :text="$t('orders.list.filter_past')"
            @click="setOrderTypeFilter('in_past')"
          />
          <ToggleButton
            class="status"
            :active="!!filters.creator_string"
            :text="$t('orders.list.filter_my')"
            @click="toggleMyOrders"
          />
        </div>
      </template>
      <template #right-side>
        <div class="d-flex align-items-end">
          <div class="d-flex gap-3">
            <div class="input-group">
              <HeaderNavbarSearch
                v-model="searchQuery"
                v-model:sort="sortState"
                :sort-options="sortOptions"
                :searchBarWidth="400"
              />
            </div>

            <FilterButton
              :layout="listLayout"
              v-model="filters"
              @changeLayout="changeLayout"
              @submitFilters="fetchWithFilters"
              @resetFilters="resetFilters"
            />
            <router-link
              to="/dashboard/orders/create"
              class="btn btn-primary"
              v-tooltip="$t('orders.create_button')"
              v-if="featureStore.featureCanCreateEditOrder"
            >
              <IconPlus class="me-1" :size="16" /> {{ $t('orders.create.btn') }}
            </router-link>
          </div>
        </div>
      </template>
    </HeaderNavbar>

    <div class="container-lead">
      <div class="row row-cards">
        <div class="col-12">
          <RemoteListView
            endpoint="/v1/account/orders"
            :search-query="searchQuery"
            :order="sortState.key"
            :order-dir="sortState.direction"
            :page-size="10"
            :reset-page="data.resetPage"
            :parameters="data.requestParameters"
            @page-resetted="data.resetPage = false"
          >
            <template #empty>
              <div class="card card-body empty my-3">
                <div class="empty-icon">
                  <IconMoodEmpty :size="24" :stroke-width="1.2" />
                </div>
                <p class="empty-title">No Orders found</p>
                <p class="empty-subtitle text-muted">
                  Try adjusting your search or filters to find what you're looking for. Or create a new order.
                </p>
                <div class="empty-action">
                  <router-link to="/dashboard/orders/create" class="btn btn-primary">
                    Create new Order <i class="ti ti-plus"></i>
                  </router-link>
                </div>
              </div>
            </template>
            <template #loading>
              <div class="card placeholder-glow">
                <div class="card-body">
                  <div v-for="i in new Array(4)" :key="i" class="d-flex align-items-center gap-4">
                    <div class="avatar placeholder" />
                    <div class="d-flex col-4 flex-column gap-2 me-auto">
                      <div class="placeholder col-8 mb-2" />
                      <div class="placeholder placeholder-xs col-4"></div>
                    </div>

                    <div class="placeholder col-3" />
                    <div class="placeholder col-3" />
                  </div>
                </div>
              </div>
            </template>
            <template #collection="{ collection }">
              <div :class="listLayout === 'list' ? 'card card-body collection-wrapper' : 'grid-wrapper'">
                <component
                  :is="listLayout === 'list' ? OrderListElement : OrderGridElement"
                  v-for="(item, index) in collection"
                  :key="item.id"
                  :order="(item as Order)"
                  class="animate__animated animate__fadeInUp animate__faster"
                  :class="listLayout === 'list' ? '' : 'card card-body'"
                  :style="{ animationDelay: `${index * 0.1}s` }"
                />
              </div>
            </template>
            <template #footer="{ page, changePage, startRecordIndex, endRecordIndex, filterCount, pageCount }">
              <FixedFooterSection>
                <p class="m-0">
                  <strong>{{ $t('orders.pagination.page') }} {{ page }} {{ $t('common.of') }} {{ pageCount }}</strong>
                  <span class="text-muted">
                    ({{ startRecordIndex }}-{{ endRecordIndex }} {{ $t('common.of') }} {{ filterCount }}
                    {{ $t('nav.orders') }})
                  </span>
                </p>
                <div class="d-flex gap-3">
                  <button class="btn" :disabled="page <= 1" @click="changePage(page - 1)">
                    {{ $t('orders.pagination.prev') }}
                  </button>
                  <button class="btn" :disabled="page >= pageCount" @click="changePage(page + 1)">
                    {{ $t('orders.pagination.next') }}
                  </button>
                </div>
              </FixedFooterSection>
            </template>
          </RemoteListView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { IconMoodEmpty, IconPlus } from '@tabler/icons-vue';
import OrderListElement from '@/components/orders/OrderListElement.vue';
import OrderGridElement from '@/components/orders/OrderGridElement.vue';
import FixedFooterSection from '@/components/generic/FixedFooterSection.vue';
import RemoteListView from '../../components/RemoteListView.vue';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import { createFilterEQL, createURLQueryList } from '@/filters/listFilters';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';

import type { SortState } from '@/components/generic/SortButton.vue';
import FilterButton from '@/components/orders/FilterButton.vue';

import { useRoute } from 'vue-router';
import translator from '@/locale/translator';
import type { Order } from '@/types/types';
import ToggleButton from '@/components/common/ToggleButton.vue';
import { useUserStore } from '@/stores/user';
import router from '@/router/router';

const defaultFilters: Record<string, any> = {
  workplace_location: '',
  language: [],
  created_at: '',
  creator_string: '',
  filter_order_lists: '',
};

const route = useRoute();
const userStore = useUserStore();
const featureStore = useCustomerFeaturesStore();
const searchQuery = ref('');
const sortState = ref<SortState>({
  key: 'created_at',
  direction: 'desc',
});

const listLayout = ref<'list' | 'grid'>('list');
const filters = ref<Record<string, any>>({ company_name: route.params.company_name, ...defaultFilters });

const data = reactive({
  sortKey: 'created_at',
  sortDir: 'desc',
  searchQuery: '',
  resetPage: false,
  requestParameters: {
    EQL: createFilterEQL({ company_name: route.params.company_name }),
    resolve: 'company,company.logo_image,stats_cache,creator',
  },
});

/* ------------------------------- ON CREATED ------------------------------- */
readQueryFilters();

/* -------------------------------- COMPUTED -------------------------------- */
const pageTitle = computed(() => {
  let pageName = translator.get('orders.list.title') as string;
  if (route.params.company_name) {
    pageName = pageName.replace(/\s+/, ` "${route.params.company_name}" `);
  }
  return pageName;
});
const sortOptions = computed(() => {
  let options = [
    { key: 'id', label: 'ID' },
    { key: 'created_at', label: translator.get('sort.field.created_at') },
    { key: 'title', label: translator.get('orders.list.sort.title') },
  ];

  if (searchQuery.value) {
    options.push({ key: 'idfs', label: translator.get('sort.field.relevance') });
  }

  return options;
});

const listBreadcrumbs = computed(() => {
  const crumbs: any[] = [{ label: translator.get('nav.orders'), path: { name: 'orders.list' } }];
  if (route.params.company_name) {
    crumbs.push({
      label: route.params.company_name,
      path: { name: 'orders.list', params: { company_name: route.params.company_name } },
    });
  }
  return crumbs;
});

/* ---------------------------------- WATCH --------------------------------- */
watch(
  () => route.params.company_name,
  (newValue) => {
    filters.value = { ...filters.value, company_name: newValue || '' };
    fetchWithFilters();
  }
);
watch(searchQuery, (value) => {
  sortState.value = value ? { key: 'idfs', direction: 'asc' } : { key: 'created_at', direction: 'desc' };
});

/* --------------------------------- METHODS -------------------------------- */
function readQueryFilters() {
  if (route.query) {
    const query = route.query;
    if (Object.keys(query).length) {
      const queryFilters = { ...filters.value };
      Object.keys(queryFilters).forEach((key) => {
        if (query[key]) {
          queryFilters[key] = query[key];
        }
      });
      filters.value = queryFilters;
      data.requestParameters.EQL = createFilterEQL(filters.value);
    }
  }
}
function changeLayout(layout: 'list' | 'grid') {
  listLayout.value = layout;
}
function setOrderTypeFilter(type: '' | 'all_active' | 'in_past') {
  filters.value.filter_order_lists = type;
  fetchWithFilters();
}
function fetchWithFilters() {
  data.requestParameters.EQL = createFilterEQL(filters.value);
  try {
    //change the query params
    const currentQueryParams = route.query;
    const newQueryParams = createURLQueryList(filters.value, currentQueryParams);
    router.push({ query: newQueryParams });
  } catch (error) {
    // Low level error, no need to show errors to the user
    console.error('Error updating query params', error);
  }
}
function resetFilters() {
  filters.value = { company_name: route.params.company_name, ...defaultFilters };
}
function toggleMyOrders() {
  filters.value.creator_string = filters.value.creator_string ? '' : userStore.currentUser?.id || '';
  fetchWithFilters();
}
</script>
<style scoped>
.collection-wrapper {
  /* remove margin later, left for pagination space */
  margin-bottom: 1em;
}
.collection-wrapper > div:not(:first-of-type),
.placeholder-glow .card-body > div:not(:first-of-type) {
  border-top: 1px solid var(--tblr-border-color);
}
.collection-wrapper > div,
.placeholder-glow .card-body > div {
  padding: 1rem 0;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-auto-rows: auto;
  gap: 16px;
}
.filters-list {
  display: flex;
  gap: 0.3rem;
}
</style>
