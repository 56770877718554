<template>
  <div v-if="featureStore.featurePoolMirrorCampaign">
    <HeaderNavbar
      :title="$t('nav.cvanalyzer')"
      :breadcrumbs="[{ label: $t('cvanalyzer.list.title'), path: { name: 'cvanalyzer.list' } }]"
    >
      <HeaderNavbarSearch :model-value="searchQuery"></HeaderNavbarSearch>
    </HeaderNavbar>
    <div class="container-lead"></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import HeaderNavbarSearch from '../header/HeaderNavbarSearch.vue';

const featureStore = useCustomerFeaturesStore();

const searchQuery = ref('');
</script>

<style></style>
