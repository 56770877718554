export default {
  language: 'Sprache',
  sign_out: 'Abmelden',
  sign_in: 'Anmelden',
  email_address: 'E-Mail Adresse',
  password: 'Passwort',
  'app.something_wrong': 'Etwas ist schiefgelaufen. Versuchen Sie es später noch einmal.',
  'app.init_loading': 'Anwendung wird vorbereitet',
  'app.init_failure':
    'PJAS LEAD konnte nicht initialisiert werden. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie den Support.',
  'login.title': 'Mit Ihrem {app}-Konto anmelden',
  'forgot_password.title': 'Passwort vergessen',
  'reset_password.title': 'Ihr {app}-Passwort zurücksetzen',
  'reset_password.reset_button': 'Zurücksetzungs-E-Mail anfordern',
  'reset_password.email_sent': 'Ein Zurücksetzungslink wurde gesendet, wenn ein Konto mit dieser Adresse verknüpft ist',
  'reset_password.wrong_link': 'Falscher Zurücksetzungslink. Versuchen Sie es später noch einmal',
  'login.remember_me': 'Angemeldet bleiben',
  'login.password_placeholder': 'Ihr Passwort',
  'nav.home': 'Home',
  'nav.orders': 'Aufträge',
  'nav.active_poolmirror_campaign': 'Portfolio Kampagne',
  'nav.cvanalyzer': 'CV Assistent',
  'home.see_all_orders': 'Alle Aufträge anzeigen',
  'home.see_all_orders_heading': 'Bestehende Aufträge anzeigen',
  'home.see_all_orders_subheading': 'Hier finden Sie alle Ihre bestehenden Aufträge',
  'home.see_all_poolmirror': 'Zur Kampagne',
  'home.see_all_poolmirror_heading': 'Ihre Portfolio Kampagne',
  'home.see_all_poolmirror_subheading': 'Ihr Portfolio-Slot-Abo mit Auslastung und Kampagnen auf einen Blick.',
  'home.create_order': 'Neuen Auftrag erstellen',
  'home.create_order_heading': 'Neuen Auftrag erstellen?',
  'home.create_order_subheading': 'Geben Sie Ihrer Stellenanzeige einen Boost.',
  'portfolio.crawled_jobs': 'Gecrawlte Stellen',
  'portfolio.campaign_slots': 'Kampagnen-Slots',
  'portfolio.slot_usage': 'Slot Auslastung',
  'portfolio.subscription': 'Abonnement',
  'portfolio.subscription_ends_in': 'Endet',
  'portfolio.available_jobs': 'Verfügbare Stellenanzeigen',
  'portfolio.show_all_slots': 'Alle Slots anzeigen',
  'portfolio.job_search_placeholder': '"/" um nach Stellen zu suchen',
  'search.generic_placeholder': 'Drücken Sie "/" um zu suchen',
  'cvanalyzer.list.title': 'Verarbeitete Lebensäufe',
  'is_loading.generic': 'Daten werden geladen...',
  'sort.direction': 'Sortierungsrichtung',
  'sort.field.created_at': 'Erstellungsdatum',
  'sort.field.relevance': 'Relevanz',
  'orders.list.sort.title': 'Auftragstitel',
  'orders.layout.list': 'Liste',
  'orders.layout.grid': 'Gitter',
  'orders.list.title': 'Alle Aufträge',
  'orders.list.filter_all': 'Alle Aufträge',
  'orders.list.filter_active': 'Active Aufträge',
  'orders.list.filter_past': 'Vergangene Aufträge',
  'orders.list.filter_my': 'Meine Aufträge',
  'orders.create': 'Auftrag erstellen',
  'orders.create_button': 'Auftrag erstellen',
  'orders.list.total_clicks': 'Klicks',
  'orders.list.impressions': 'Impressionen',
  'orders.list.days': 'Tage',
  'orders.list.worldwide': 'Weltweit',
  'orders.pagination.page': 'Seite',
  'orders.pagination.prev': 'Vorherige',
  'orders.pagination.next': 'Nächste',
  'orders.filters.list_style': 'Listenstil',
  'orders.filters.time_range': 'Zeitbereich',
  'orders.filters.company_name': 'Unternehmen',
  'orders.filters.creator': 'Creator',
  'orders.filters.location': 'Arbeitsort',
  'orders.filters.language': 'Sprache',
  'orders.filters.reset_filters': 'Zurücksetzen',
  'orders.filters.apply': 'Anwenden',

  'orders.filters.everything': 'Alles',
  'orders.filters.today': 'Heute',
  'orders.filters.thisWeek': 'Diese Woche',
  'orders.filters.thisMonth': 'Diesen Monat',
  'orders.filters.thisYear': 'Heuer',
  'orders.filters.last7': 'Letzte 7 Tage',
  'orders.filters.last30': 'Letzte 30 Tage',
  'orders.filters.last90': 'Letzte 90 Tage',

  'btn.continue': 'Weiter',
  'btn.back': 'Zurück',
  'btn.duplicate': 'Duplizieren',
  'btn.delete': 'Löschen',
  'common.message': 'Nachricht',
  'common.step': 'Schritt',
  'common.of': 'von',
  'common.comment': 'Kommentar',
  'common.done': 'Fertig',
  'common.workspace': 'Arbeitsbereich',
  'common.company': 'Unternehmen',
  'common.title': 'Titel',
  'common.job': 'Job',
  'common.dates': 'Dates',
  'common.duration': 'Dauer',
  'common.impressions': 'Impressionen',
  'common.clicks': 'Clicks',
  'datepicker.startdate': 'Startdatum',
  'datepicker.select_start': 'Kampagnenbeginn auswählen',
  'datepicker.date_help': 'Bitte definieren Sie die Laufzeit Ihrer Kampagne.',
  'datepicker.days': 'Tage',
  'dropzone.drop_browse': 'Dateien ziehen & ablegen oder durchsuchen',
  'dropzone.drop_msg': 'Dateien hier ablegen',

  'orders.create.wizard': 'Assistent',
  'orders.create.form.disabled': 'Bitte geben Sie die URL Ihrer Stellenanzeige ein.',
  'orders.create.full_form': 'Vollständiges Formular',
  'orders.create.btn': 'Auftrag erstellen',
  'orders.create.title_create': 'Neuen Auftrag erstellen',
  'orders.create.title_modify': 'Auftrag ändern und neu erstellen',
  'orders.create.title_confirm': 'Bitte bestätigen Sie den Auftrag',
  'orders.wizard.intro.heading': 'Hallo',
  'orders.wizard.intro.subheading':
    'Lassen Sie mich Ihnen helfen, Ihre Programmatische Kampagne zu Konfigurieren. Ich lerne noch, aber ich werde versuchen, Ihnen bei der Zusammenstellung der erforderlichen Daten zu helfen.',
  'orders.wizard.intro.job_posting': 'Ihre Stellenanzeige',
  'orders.wizard.steps.url': 'Online Publikations-URL',

  'orders.wizard.intro.url_enter': 'Geben Sie die URL Ihrer Stellenanzeige ein.',
  'orders.wizard.intro.url_help': ' Die Seite hinter dieser URL sollte ausschließlich die Stellenanzeige enthalten.',
  'orders.wizard.products.title': 'Produktauswahl',
  'orders.wizard.products.subtitle': 'Wo möchten Sie die Stellenanzeige publizieren?',
  'orders.wizard.products.products': 'Produkte',
  'orders.wizard.products.product': 'Produkt',
  'orders.wizard.products.min_budget': 'Min Budget',
  'orders.wizard.products.notice': 'Bitte wählen Sie mindestens ein Produkt.',
  'orders.wizard.products.total_min_budget': 'Laufzeit Min Budget',
  'orders.wizard.products.min_duration': 'Minimale Dauer',
  'orders.wizard.products.daily_min_budget': 'Tägliches Min Budget',

  'orders.wizard.platforms.title': 'Plattformauswahl',
  'orders.wizard.platforms.subtitle':
    'Wo sollen wir Ihre Stellenanzeige veröffentlichen? Bitte wählen Sie die Plattformen, die Sie nutzen möchten.',
  'orders.wizard.platforms.platforms': 'Plattformen',

  'orders.wizard.analytics.title': 'Stellen Analyse',
  'orders.wizard.analytics.subtitle':
    'Wir analysieren derzeit Ihre Online-Stellenanzeige. Dies kann einige Sekunden dauern...',
  'orders.wizard.analytics.subtitle_completed': 'Stellenanzeige wurde erfolgreich analysiert.',
  'orders.wizard.analytics.campaign_duration': 'Kampagnendauer',
  'orders.wizard.analytics.campaign_budget': 'Kampagnenbudget',

  'orders.wizard.analytics.total_budget': 'Gesamtbudget',
  'orders.wizard.analytics.daily': 'Täglich',
  'orders.wizard.analytics.budget_help':
    'Bitte definieren Sie das Budget Ihrer Kampagne. Das minimale Budget ist CH 123 bei einer Laufzeit von 30 Tagen.',
  'orders.wizard.analytics.crawling': 'Crawling der Stellenanzeige',
  'orders.wizard.analytics.connecting': 'Verbindung zum Roboter',

  'orders.wizard.details.title': 'Stellen Details',
  'orders.wizard.details.subtitle': 'Bitte überprüfen und vervollständigen Sie die Details Ihrer Anzeige.',
  'orders.wizard.details.job_title': 'Stellenbezeichnung / Position',
  'orders.wizard.details.job_title_help':
    'Erfassen Sie die Stellenbezeichnung, idealerweise kurz und prägnant one Pensumangabe (80% etc.).',
  'orders.wizard.details.company_name': 'Unternehmensname',
  'orders.wizard.details.company_name_help': 'Der Name des einstellenden Unternehmens. Dies wird öffentlich angezeigt.',
  'orders.wizard.details.workload_min': 'Pensum min',
  'orders.wizard.details.workload_max': 'Pensum max',
  'orders.wizard.details.location': 'Arbeitsplatz Standort',
  'orders.wizard.details.location_help':
    'Der Standort des Arbeitsplatzes. Dies wird öffentlich angezeigt und in einigen Fällen für Geotargeting verwendet.',

  'orders.wizard.media.title': 'Medien',
  'orders.wizard.media.subtitle': 'Optional, laden Sie Bilder / Logos für diesen Auftrag hoch',
  'orders.wizard.media.company_logo': 'Firmenlogo',
  'orders.wizard.media.order_images': 'Bilder',

  'orders.wizard.remarks.title': 'Bemerkungen',
  'orders.wizard.remarks.subtitle':
    'Darf ich Ihnen bei der Erstellung Ihreres Auftrages helfen? Ich lerne zwar noch, werde aber mein bestes geben, Sie bei der Zusammenstellung der Kampagne zu unterstützen.',
  'orders.wizard.remarks.msg_placeholder': 'Bitte teilen Sie uns mit...',

  'orders.create.form.ref_number': 'Referenz Nr.',
  'orders.create.form.ref_number_help':
    'Das ist die Referenznummer in Ihrem System. Wird automatisch generiert, wenn das Feld leer gelassen wird.',
  'orders.create.form.images_empty': 'Auftrag enthält keine Bilder',
  'orders.create.form.products_empty': 'Order has no products chosen',
  'orders.create.form.save_confirm': 'Speichern & Bestätigen',

  'orders.details.title': 'Auftragsdetails',
  'orders.details.clicks': 'Klicks',
  'orders.details.impressions': 'Impressionen',
  'orders.details.cost_per_click': 'Kosten pro Klick',
  'orders.details.cpc_waiting': 'Warten auf mehr Daten',
  'orders.details.total_cost': 'Gesamtkosten',
  'orders.details.better_than_yesterday': 'besser als gestern',
  'orders.details.worse_than_yesterday': 'schlechter als gestern',
  'orders.details.remarks.empty': 'Es gibt keine Bemerkungen zu diesem Auftrag',
  'orders.details.channels': 'Produkte / Kanäle',
  'orders.details.info': 'Info',
  'orders.details.preview': 'Vorschau',
  'orders.details.packages': 'Pakete',
  'orders.details.support': 'Support',
  'orders.details.job_posting': 'Stelleninserat',
  'orders.details.channels.empty': 'Keine Kanäle für diesen Auftrag gefunden',
  'orders.details.delete_order.title': 'Diesen Auftrag löschen?',
  'orders.details.delete_order.description':
    'Sind Sie sicher, dass Sie diesen Auftrag löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',

  'nav.promptsuite': 'RecruitingIQ',
  'promptsuite.apps.title': '{filter} Apps',
  'promptsuite.apps.subtitle': 'Verfügbare KI-Apps',
  'promptsuite.show_advanced_options': 'Erweiterte Optionen anzeigen',
  'promptsuite.additional_user_instructions': 'Zusätzliche Anweisungen',
  'promptsuite.additional_user_instructions_placeholder':
    'Beispiel: Schreibe es so einfach, dass es ein 4-jähriges Kind verstehen könnte.',
  'promptsuite.additional_user_instructions.hint':
    'Hier können Sie zusätzliche Anweisungen angeben, denen die KI beim Erstellen des Texts folgen soll.',
  'promptsuite.generate': 'Generieren',
  'promptsuite.sidebar.title': 'Eingabe',
  'promptsuite.sidebar.language': 'Sprache',

  'promptsuite.apps.filter.all': 'Alle',
  'promptsuite.apps.filter.jobposting': 'Stellenanzeige',
  'promptsuite.apps.filter.socialmedia': 'Soziale Medien',
  'promptsuite.apps.filter.activesourcing': 'Active Sourcing',
  'promptsuite.apps.filter.interview': 'Interview',
  'promptsuite.apps.filter': 'Filter',
  'promptsuite.empty_hint': 'Bitte geben Sie Ihre Informationen in das linke Panel ein.',

  'promptsuite.linkedinsearch.additional_skills': 'Zusätzliche Fähigkeiten',
  'promptsuite.linkedinsearch.additional_skills.placeholder': 'Beispiel: Salesforce, CRM, Verkaufslösungen',

  'promptsuite.linkedinsearch.additional_tasks': 'Zusätzliche Aufgaben',
  'promptsuite.linkedinsearch.additional_tasks.placeholder': 'Beispiel: Lead-Generierung, Kundenakquise',

  'promptsuite.linkedinsearch.other_titles': 'Zusätzliche Stellentitel',
  'promptsuite.linkedinsearch.other_titles.placeholder': 'Beispiel: Verkaufsleiter, Verkaufsvertreter',

  'promptsuite.linkedinsearch.exclude': 'Auszuschliessende Stellentitel, Fähigkeiten, Aufgaben, Keywords',
  'promptsuite.linkedinsearch.exclude.placeholder': 'Beispiel: Call Center, Kundenservice',

  'promptsuite.linkedinsearch.open_in_linkedin': 'In LinkedIn öffnen',

  'promptsuite.feedback.title': 'Rückmeldung',
  'promptsuite.feedback.subtitle': `Ihr Feedback ist uns wichtig. Wenn Sie Probleme mit der App oder dem von ihr erstellten Inhalt hatten, teilen Sie bitte Ihre Erfahrungen mit, damit wir unseren Service verbessern können.`,
  'promptsuite.feedback.message': 'Bitte beschreiben Sie Ihre Erfahrung...',
  'promptsuite.feedback.send': 'Feedback senden',
  'promptsuite.feedback.cancel': 'Abbrechen',
  'promptsuite.feedback.thank_you': 'Vielen Dank für Ihr Feedback!',

  'eula.accept': 'EULA akzeptieren',
  temperature: 'Temperatur',
  'temperature.p1':
    'Der Temperaturparameter steuert die Zufälligkeit oder Kreativität der Ausgabe des Modells. Es handelt sich um einen Hyperparameter, der die Wahrscheinlichkeitsverteilung der vom Modell generierten Token (Wörter oder Zeichen) beeinflusst.',
  'temperature.p2':
    'Ein höherer Temperaturwert (z.B. 1,0 oder höher) führt dazu, dass das Modell vielfältigere und kreativere Antworten erzeugt, jedoch mit dem Risiko, weniger kohärent oder genau zu sein. Ein niedrigerer Temperaturwert (z.B. 0,1 oder darunter) führt dazu, dass das Modell konzentriertere, konservativere und deterministischere Ausgaben erzeugt, die eng an die Muster angelehnt sind, die es während seiner Schulung gesehen hat.',
  'template.hint':
    'Die Vorlage verändert den Schreibstil der Stellenanzeige. Wählen Sie eine Vorlage, die am besten zu Ihrer Zielgruppe passt, um optimale Ergebnisse zu erzielen.',

  'settings.accounts.title': 'Konten',
  'settings.accounts.subtitle': 'Einstellungen / Konten',
  'settings.accounts.description': 'Erstellen und verwalten Sie Ihre Konten',

  'settings.companies.title': 'Firmen',
  'settings.companies.subtitle': 'Einstellungen / Firmen',
  'settings.companies.description': 'Erstellen und verwalten Sie Ihre Unternehmen',

  'company.images': 'Firmenbilder',
  'company.images.title': 'Firmenbilder',
  'company.images.logo.title': 'Firmenlogo',
  'company.images.logo.subtitle': 'Laden Sie optional Medien für dieses Unternehmen hoch',
  'company.images.media.title': 'Unternehmensmedien',
  'company.images.media.subtitle': 'Laden Sie optional Medien für dieses Unternehmen hoch',
  'company.images.media.success_update_message': 'Firmenbilder aktualisiert',
  'company.details': 'Firmendetails',
  'company.profile': 'Profil',
  'company.details.personal_details': 'Persönliche Daten',
  'company.details.name': 'Name der Firma',
  'company.details.homepage': 'Firmenhomepage',
  'company.details.address': 'Adresse',
  'company.details.address_street': 'Straße',
  'company.details.address_city': 'Stadt',
  'company.details.address_zip': 'PLZ',
  'company.details.address_country': 'Land',
  'company.create.button': 'Unternehmen Gründen',
  'company.save.button': 'Unternehmen speichern',

  'nav.settings': 'Einstellungen',
  'settings.title': 'Einstellungen',
  'settings.subtitle': 'Einstellungen / Übersicht',
  'settings.all_settings': 'Alle Einstellungen',

  'nav.account': 'Konto',
  'account.settings': 'Kontoeinstellungen',
  'account.create.button': 'Konto Erstellen',

  'account.profile': 'Profil',
  'account.profile.save': 'Konto speichern',
  'account.profile.personal_details': 'Persönliche Details',
  'account.profile.firstname': 'Vorname',
  'account.profile.lastname': 'Nachname',
  'account.profile.email': 'E-Mail-Adresse',
  'account.profile.email_help':
    'Dies ist die E-Mail-Adresse, die Sie zum Anmelden verwenden und über die wir Sie kontaktieren werden.',
  'account.profile.admin.title': 'Admin-Berechtigung',
  'account.profile.admin.text': 'Ist Benutzer Admin?',
  'account.profile.admin_help':
    'Indem Sie dieses Konto zum Admin machen, werden alle Berechtigungsprüfungen umgangen. Darüber hinaus haben nur Admin-Konten die Möglichkeit, andere Konten zu bearbeiten, zu erstellen und zu löschen.',

  'account.password': 'Passwort & Sicherheit',
  'account.password.password': 'Passwort',
  'account.password.change': 'Passwort ändern',
  'account.password.new_field': 'Neues Passwort',
  'account.password.repeat_field': 'Passwort wiederholen',
  'account.password.set_new': 'Neues Passwort festlegen',
  'account.password.passwords_need_to_match': 'Die eingegebenen Passwörter müssen übereinstimmen.',
  'account.password.change_help':
    'Bitte geben Sie Ihr neues Passwort zweimal ein, um sicherzustellen, dass keine Tippfehler vorliegen.',
  'account.password.explain': 'Sie können das Passwort Ihres Kontos jederzeit ändern.',

  'account.experience': 'Erfahrung',
  'account.experience.give_feedback': 'Feedback geben',

  'order.status.to_be_confirmed': 'Pendent',
  'order.status.confirmed': 'Bestätigt',
  'order.status.running': 'Aktiv',
  'order.status.finished': 'Beendet',

  'order.chat.no_messages': 'Es gibt noch keine Nachrichten hier.',

  'order.job_posting.currently_crawling': 'Wir verarbeiten Ihre Stellenanzeige. Bitte haben Sie einen Moment Geduld.',
  'order.job_posting.could_not_crawl': 'Wir konnten Ihre Stellenanzeige nicht automatisch herunterladen.',
  'order.job_posting.could_not_crawl_text': 'Bitte geben Sie den Stellenanzeigentext manuell ein, um fortzufahren.',

  'order.job_posting.crawler_status.waiting': 'Warten',
  'order.job_posting.crawler_status.success': 'Erfolg',
  'order.job_posting.crawler_status.in_progress': 'In Arbeit',
  'order.job_posting.crawler_status.error': 'Fehler',
  'order.job_posting.crawler_status.blocked': 'Blockiert',
  'order.job_posting.crawler_status.blocked_by_subflow': 'Blockiert durch Unterfluss',
  'order.job_posting.crawler_status.exec_canceled': 'Ausführung abgebrochen',
  'order.job_posting.crawler_status.postpone_scheduled': 'Postponiert',
  'order.job_posting.crawler_status.postponed': 'Postponiert',
  'order.job_posting.crawler_status.unknown': 'Unbekannter Status',
};
