<template>
  <div class="d-flex flex-column align-items-center">
    <div v-if="crawlerTask && crawlerTask.status !== 1 && crawlerTask.status !== -1">
      <div>
        <div class="text-center">
          <div class="mb-3"></div>
          <h4>{{ $t('order.job_posting.currently_crawling') }}</h4>
          <div class="text-secondary mb-3">{{ $t(crawlerTaskStatuses[crawlerTask.status]) }}</div>
        </div>
        <div class="p-3 text-center">
          <div class="spinner-border"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="crawlerTask && crawlerTask.status === -1" class="alert alert-warning" role="alert">
        <div>
          <h4>{{ $t('order.job_posting.could_not_crawl') }}</h4>
          <div class="text-secondary">
            {{ $t('order.job_posting.could_not_crawl_text') }}
          </div>
        </div>
      </div>
      <div :id="editorId" v-once @keydown="handleKeyDown"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { request } from '@/api/client';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';

const props = defineProps<{
  orderId: string;
}>();

const crawlerTaskStatuses = {
  waiting: 'order.job_posting.crawler_status.waiting',
  in_progress: 'order.job_posting.crawler_status.in_progress',
  success: 'order.job_posting.crawler_status.success',
  error: 'order.job_posting.crawler_status.error',
  blocked: 'order.job_posting.crawler_status.blocked',
  blocked_by_subflow: 'order.job_posting.crawler_status.blocked_by_subflow',
  exec_canceled: 'order.job_posting.crawler_status.exec_canceled',
  postpone_scheduled: 'order.job_posting.crawler_status.postpone_scheduled',
  postponed: 'order.job_posting.crawler_status.postponed',
  unknown: 'order.job_posting.crawler_status.unknown',
};

const toast = useToast();
const editorData = ref();
const crawlerTask = ref();

// We'll store a reference to the Editor.js instance
let editor: EditorJS | null = null;

/**
 * Generate a unique ID to use as the Editor.js holder.
 * In practice, you can pass this in as a prop or generate it however you like.
 */
const editorId = `editorjs-${Math.random().toString(36).slice(2, 9)}`;

/**
 * Initialize Editor.js when component mounts
 */
onMounted(() => {
  request('GET', `/v1/account/order/${props.orderId}/meta/job_posting`)
    .then((res) => {
      editorData.value = res.data || [];
      crawlerTask.value = res.task || null;

      try {
        editor = new EditorJS({
          holder: editorId, // use the generated ID for the holder
          data: { blocks: editorData.value as any },
          onChange: async () => {
            if (!editor) return;
            const newData = await editor.save();
            request('PUT', `/v1/account/order/${props.orderId}/meta/job_posting`, {}, { data: newData.blocks })
              .then(() => {
                // toast.success('Document saved successfully');
              })
              .catch((error: any) => {
                toast.error(`Request failed: ${error.responseJson?.message ?? error.message}`);
              });
          },
          tools: {
            header: {
              class: Header as any,
              inlineToolbar: true,
            },
            list: {
              class: List as any,
              inlineToolbar: true,
            },
          },
        });
      } catch (err) {
        console.error('Error initializing Editor.js:', err);
      }
    })
    .catch((error: any) => {
      console.error(error);
    });
});

function handleKeyDown(event: any) {
  // Check for Shift + Enter
  if (event.shiftKey && event.key === 'Enter') {
    event.preventDefault(); // Prevent default behavior (new div in Safari)

    // Get the current selection
    const selection = window.getSelection();
    if (!selection?.rangeCount) return;

    const range = selection.getRangeAt(0);
    // Insert a newline character
    const newline = document.createElement('br');
    range.insertNode(newline);

    // Move the cursor after the newline
    range.setStartAfter(newline);
    range.setEndAfter(newline);
    selection.removeAllRanges();
    selection.addRange(range);

    // Notify Editor.js of the change (optional, depending on your setup)
    editor!.saver.save();
  }
}

/**
 * If this component can be destroyed, ensure Editor.js is cleaned up.
 */
onBeforeUnmount(() => {
  editor = null;
});
</script>
<style>
.ce-block .ce-block__content {
  margin-left: 60px;
}

.ce-toolbar__content {
  margin: 0 0 0 6px;
}

.ce-toolbar__content .ce-toolbar__actions {
  left: -3px;
  top: 2px;
  right: auto;
}
</style>
