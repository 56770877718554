<template>
  <header :class="['sticky-top', props.noBottomMargin ? 'mb-0' : 'mb-3']">
    <div :class="{ 'navbar navbar-light navbar-lead-head': true, 'navbar-noborder': props.noBorder }">
      <div class="header-container">
        <ol class="breadcrumb breadcrumb-arrows">
          <li class="breadcrumb-item">
            <RouterLink to="/" custom v-slot="{ navigate }">
              <IconSmartHome @click="navigate" @keypress.enter="(e: any) => navigate(e)" role="link" :size="20" />
            </RouterLink>
          </li>
          <li class="breadcrumb-item" v-for="breadcrumb in props.breadcrumbs" :key="breadcrumb.label">
            <RouterLink :to="breadcrumb.path">{{ breadcrumb.label }}</RouterLink>
          </li>
        </ol>
        <div class="header-sides">
          <section class="left-side">
            <h2 class="header-title">{{ $props.title }}</h2>
            <slot name="left-side" />
          </section>
          <section class="right-side">
            <slot name="right-side" />
          </section>
        </div>
        <slot name="bottom" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { IconSmartHome } from '@tabler/icons-vue';
import type { RouteLocationRaw } from 'vue-router';

// Props
export interface Props {
  breadcrumbs: { label: string; path: RouteLocationRaw }[];
  title: string;
  noBorder?: boolean;
  noBottomMargin?: boolean;
}

const props = defineProps<Props>();
</script>
<style scoped>
.navbar-noborder {
  --tblr-navbar-border-color: transparent;
  box-shadow: none;
}
.header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 2rem;
  gap: 0.4rem;
  width: 100%;
}
.header-title {
  font-weight: 700;
}
.header-sides {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right-side,
.right-side > * {
  height: 100%;
}
.breadcrumb {
  --tblr-breadcrumb-item-padding-x: 0.6rem;
  --tblr-breadcrumb-font-size: 14px;
  align-items: center;
}
.breadcrumb .breadcrumb-item svg {
  color: #667085;
  cursor: pointer;
}
.breadcrumb .breadcrumb-item a {
  color: #475467;
  padding: 4px 8px;
  vertical-align: middle;
}
.breadcrumb .breadcrumb-item a.router-link-active.active {
  background-color: #f9fafb;
  font-weight: 600;
}
.breadcrumb .breadcrumb-item:before {
  color: #d0d5dd;
  font-size: 16px;
}
</style>
